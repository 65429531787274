<script>
import BaseVue from '@frontend/Base.vue';
import Gen from '../helper/Gen';

export default {
	extends: BaseVue,
	data() {
		return {
			row: false,
			products: [],
			totalProducts: 0,
			recipe: false,
		}
	},
	async mounted() {
		await this.ready();
		this.getDetail()
		setTimeout(()=>{ $("a[href$='resep']").closest("li").addClass("active") }, 300)
	},
	methods: {
		getDetail(){
			this.loadingOverlay = true
			this.row = false
			Gen.apirest("/recipe-detail/"+this.$route.params.slug, {}, (err, resp)=>{
				this.loadingOverlay = false
				if (resp.code == 404) return this.$router.push({name:'404'})
				this.row = resp.row
				this.products = resp.products
				this.totalProducts = resp.totalProducts
				this.getAnotherRecipe()
			})
		},
		getAnotherRecipe(){
			Gen.apirest("/recipe-list", {cat:this.row.mrc_slug,notid:this.row.mr_id,limit:4}, (err, resp)=>{
				this.recipe = resp.recipe
			})
		}
	},
	watch:{
		$route(){
			this.getDetail()
			setTimeout(()=>{ $("a[href$='resep']").closest("li").addClass("active") }, 300)
		}
	}
};
</script>
<template>
	<div id="content" v-if="row">
		<section id="page-title" class="page-title-mini">
			<div class="container clearfix">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link :to="{name:'Resep'}">Recipe</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">{{row.mr_title}}</li>
				</ol>
			</div>
		</section>
		<div class="content-wrap">
			<section class="section nobg nomargin">
				<div class="container">
					<div class="recipe-post">
						<div class="row justify-content-center">
							<div class="col-md-9">
								<div class="rp-head">
									<div class="rp-title">
										<span>{{row.category}}</span>
										<h1>{{row.mr_title}}</h1>
									</div>
									<div class="writer-block">
										<span class="mr-0">Share to</span>
										<div class="social-icons share">
											<ShareNetwork network="facebook" :url="baseUrl+$route.path" :title="row.mh_seo_title"
												:description="row.mh_seo_desc"
												class="social-icon si-colored si-small si-rounded si-bordered si-facebook">
												<i class="icon-facebook"></i>
												<i class="icon-facebook"></i>
											</ShareNetwork>
											<ShareNetwork network="twitter" :url="baseUrl+$route.path" :title="row.mh_seo_title"
												class="social-icon si-colored si-small si-rounded si-bordered si-twitter">
												<i class="icon-twitter"></i>
												<i class="icon-twitter"></i>
											</ShareNetwork>
										</div>
									</div>
								</div>
								<div class="rp-img">
									<img :src="uploader(row.mr_image)" :alt="row.mr_title">
								</div>
								<div class="rp-body">
									<div class="rp-part products" v-if="totalProducts>0">
										<h3>Produk yang dipakai</h3>
										<div class="form-row justify-content-center justify-content-md-start">
											<div class="col-6 col-md-3" v-for="(v,k) in products" :key="k">
												<router-link :to="{name:'ProductsDetail',params:{slug:v.mp_slug}}" class="used-prod">
													<img :src="uploader(v.mp_image)" :alt="v.mp_title">
													<h4>{{v.mp_title}}</h4>
												</router-link>
											</div>
										</div>
									</div>
									<div class="rp-part" v-if="row.mr_type=='R'">
										<h3>Bahan - bahan</h3>
										<div class="rp-item ingredients" v-html="row.mr_ingredients"></div>
									</div>
									<div class="rp-part">
										<h3>{{row.mr_type=='R'?'Cara Membuat':''}}</h3>
										<div class="recipe-part">
											<div class="rp-item" v-html="row.mr_instruction"></div>
										</div>
									</div>
									<div class="rp-part" v-if="row.mr_nutrient.length > 1">
										<h3>Kandungan Nutrisi</h3>
										<div class="rp-head">
											<div class="rp-nutri" v-for="(v,k) in row.mr_nutrient" :key="k">
												<span>{{v.name}}</span>
												<h3>{{v.val}}</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="section nobg mt-n2 mt-md-0 pt-0 pt-md-4 add-recipe" v-if="recipe&&recipe.data.length">
				<div class="container">
					<div class="heading-block center">
						<h2>Resep Lainnya</h2>
					</div>
					<div class="more-recipes">
						<div class="row">
							<div v-for="(v,k) in recipe.data" :key="k" class="col-lg-3 col-md-4 aos-init aos-animate" data-aos="fade-up" :data-aos-delay="50*k">
								<router-link :to="{name:'DetailResep',params:{slug:v.mr_slug}}" class="recipe-item">
									<div class="ri-img">
										<img :src="uploader(v.mr_image)" alt="Recipe Image">
									</div>
									<div class="ri-desc">
										<span>{{v.category}}</span>
										<h3>{{v.mr_title}}</h3>
									</div>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>